import React from 'react'
import './Work.css'

export default function Work() {
    return (
        <div className='Work-container'>
      <h1 className='a12'>Work I've Done</h1>
      <p className='a12'>Check out UFC sports picks <a href="https://ay3xqa.github.io/myUFCAndPortfolio/">here</a></p>
      </div>
    )
}
